import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/map/mngYusaku.jpg';
import ishizaka from '../images/map/2021-1-伊藤龍生.jpg';
// import ishizakaImg from '../images/map/ishizakaImg.jpg';
// import ishizakaTop from '../images/ob/ob_ishizaka_top.jpg';
// import * as ROUTES from '../src/constants/routes';

class ItouPage extends React.Component {

  render() {
    return (
      <Fragment>
        <title>地方部員インタビュー　伊東龍生|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">地方部員インタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'black', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">１年 吉田</h4>
          <div className="mng-text">
          本日はインタビューに応じてくださり、ありがとうございます。國學院久我山高校一年生の吉田優作です。よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'black'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <h4 className="vertical-timeline-element-subtitle">１年 伊東龍生</h4>
          <div className="ob-text">
          よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          まず初めに自己紹介をお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい。國學院久我山高校１年の伊東龍生です。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。それでは早速インタビューを始めさせていただきます。國學院久我山高校への入学理由について教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          自分は、中学生の頃は勉強をおろそかにして部活ばかりをしていました。しかし、このままではいけないと思い進学校である國學院久我山へ入学をし、文武両道を果たして、自分の人生においての選択肢を増やしいと思ったからです。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。素晴らしい理由ですね！
國學院久我山高校バスケットボール部に入学してから、楽しかったことはありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          自分の中学校は、ウエイト器具がなく、腕立て伏せなどでしか筋肉をつけることができなかったのですが、國學院久我山高校バスケットボール部に入部してからは、ウエイト器具などが充実していて、そこでウエイトできることが楽しいですね！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          久我山は、ウエイト器具が揃っていて筋トレするには良い環境ですよね。続いて、バスケットボール部の良いところを教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          中学生のころと違い、自分よりもフィジカルや身長が自分と同じ、またはそれ以上の仲間と一緒に練習をして切磋琢磨しあえるところです。また、先輩達や先生、OBの方々やコーチなどがアドバイスをしてくれるので、自分が成長できる点です。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          コーチはOBの方などが練習に来てくださるというのも、國學院久我山高校バスケットボール部の特徴ですよね！！龍生さんは埼玉にお住まいですが、何故埼玉の高校ではなく、東京の國學院久我山高校を選んだのですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          酒井先生に声をかけて頂き、久我山高校バスケ部について色々知り、久我山高校バスケ部でバスケをしたいと思ったのが１番の理由ですね！あと、実は、自分の父親も久我山高校バスケットボール部のOBでして（笑）、色々話を聞く中で、学校としても素晴らしいと思い、入学を決めました！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          龍生さんのお父さんも久我山高校バスケットボール部出身なんですね！埼玉県からお通いですが、通う上で心配であったことについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい。長時間の移動になるので、電車が遅れることでの遅刻などが心配でした。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          まだ遅刻はしたことはないですよね？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい！ないです！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          電車の中ではなにをされてますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          テスト前なら暗記物、基本は英単語の勉強です。電車の時間を上手く工夫して有効活用しています！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。電車内の時間を工夫されてますね！では、次に、自分個人の目標と今年のチームの目標をお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          自分自身の目標は、上級生のフィジカルに負けず、3ポイントシュートが一流に入る選手になることです。チームの目標は、東京1位の高校になることです。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。最後に國學院久我山高校バスケットボール部に入学希望の中学生に対してメッセージをお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          自分のように都外から通おうとしてる方もいると思いますが、自分の場合、学校生活は一ヶ月程度で慣れます。部活動を本気でやりたい方にも、勉強を本気でやりたい方にも最高な学校です。部活の雰囲気もよく、心身共に成長できます。是非入部してください。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          本日はインタビューに応じて下さり、ありがとうございました！
          </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <ItouPage />
  </Layout>
);
